import React from 'react'
import moodivator from '../cards/moodivator.png';
import sky from '../cards/sky.png';
import crave from '../cards/crave.png';
import ovelia from '../cards/ovelia1.png';
import ohn from '../cards/ohn1.png';
import ProjectCard from './ProjectCard';



export default class Portfolio extends React.Component {
  
  render() {
    let cards = [
      {
        id: 1,
        title: "MooDivator",
        img: moodivator,
        description: "A fitness/crossfit app with motivating quotes",
        link: "http://moodivator.surge.sh/",
        logo: require('../assets/moodi.jpg'),
        github: "https://github.com/svetlasyrimis/MooDivator"
      },
      {
        id: 2,
        title: "Sky",
        img: sky,
        description: "A weather app with geolocation",
        link: "https://skyproject.surge.sh/",
        logo: require('../assets/sky.png'),
        github: "https://github.com/svetlasyrimis/Sky"
      },
      {
        id:3,
        title: "Crave",
        img: crave,
        description: "An app for the indecisive eater",
        link: "http://crave.surge.sh/",
        logo: require('../assets/mealm.jpg'),
        github: "https://github.com/svetlasyrimis/crave"
      },
      {
        id: 4,
        title: "Ovelia Restaurant",
        img: ovelia,
        description: "Redesigned a greek restaurant's website",
        link: "http://ovelia.surge.sh/",
        logo: require('../assets/ovelia1.jpeg'),
        github: "https://github.com/svetlasyrimis/Ovelianyc-Redesign"
      },
      {
        id:5,
        title: "OHN",
        img: ohn,
        description: "A project-management/social app",
        link: "http://ohn.surge.sh/",
        logo: require('../assets/ohn.png'),
        github: "https://github.com/svetlasyrimis/ohn"
    
      }
    ]
    return (
      <>
      <p className="section-title" id="portfolio">Projects</p>
        <div className="container">
          
          {cards.map(card => {
            return <div className="project-card" key={card.id}><ProjectCard card={card}/></div>
          })}
        </div>
        </>
      )
    }
}
