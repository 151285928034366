import React from 'react'

export default function Footer() {
  return (
    <footer>
      <div className="icons">
        <a href="https://www.linkedin.com/in/svetlasyrimis/" target="_blank" rel="noopener noreferrer">
          <img src={require('../cards/linkedin-icon.png')} className="logo-small" alt="LinkedIn" />
        </a>
        <a href="https://github.com/svetlasyrimis" target="_blank" rel="noopener noreferrer">
          <img src={require('../cards/github.png')} className="logo-small" alt="LinkedIn" />
        </a>
      </div>
      <br />
      <p className="message">Created with <span className="hearts">&hearts;</span> by <span className="name">Svetla
          Syrimis</span>
      </p>


    </footer>
  )
}
