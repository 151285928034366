import React, { Component } from 'react';
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";



export default class Contact extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      message: ""
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = () => {
    window.open(`mailto:svetla.syrimis@gmail.com?subject="Greetings from ${this.state.name}"&body=${this.state.message}`);
  }
  render() {
    return (
      <div className="contact-wrap">
      <div className="contact">
        
        <form className="flex-column" id="fs-frm" name="simple-contact-form" acceptCharset="utf-8" action="https://formspree.io/svetla.syrimis@gmail.com" method="post">
        <p className="title"><span className="contact-span">Contact</span></p>
            {/* <label htmlFor="full-name">Full Name</label> */}
            <input type="text" name="name" id="full-name" placeholder="First and Last" required/>

              {/* <label htmlFor="email-address">Email Address</label> */}
            <input type="email" name="_replyto" id="email-address" placeholder="Your email" required />
              
                {/* <label htmlFor="message">Message</label> */}
              <textarea rows="5" name="message" id="message" placeholder="Message" required ></textarea>
             
           
          <AwesomeButton  type="secondary" size="medium">SUBMIT</AwesomeButton>
          </form>
          
        </div>
        </div>
    )
  }          
}
