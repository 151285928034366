import React, { Component } from 'react'
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';


export default class Header extends Component {

  render() {

    return (
      <header>

        <h1 style={{ color: "white" }}>Svetla Syrimis</h1>
        <p className="typed-text" style={{ color: "white" }}>:~$ <Typed
          strings={[
            'Web Developer',
            'Creative',
            'Problem-solver'
          ]}
          typeSpeed={70}
          backSpeed={50}
          startDelay={3000}
          loop

        >
        </Typed></p></header>
    )
  }
}


