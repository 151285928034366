import React from 'react';
import './App.css';
import Header from './components/Header';
import Navigation from './components/Navigation';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Skills from './components/Skills';
import Footer from './components/Footer'

export default class App extends React.Component {

  render() {
    
    return (
      <div>
       
        <Navigation />
        <div className="coding-pic"><Header /></div>
        <About />
        <Skills />
        <div className="project-pic"></div>
        <Portfolio />
        <Contact id="contact" />
        <Footer />
        <hr />

      </div>
    )
  }
}

