import React, { Component } from 'react'
import { animateScroll as scroll } from "react-scroll";
import { scroller } from "react-scroll";
import { Navbar, Nav , NavDropdown } from 'react-bootstrap';

export default class Navigation extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  scrollToBottom = () => {
    scroll.scrollToBottom();
  }
  
  scrollTo(element,offset) {
    scroller.scrollTo(element, {
      activeClass:"active",
      duration: 700,
      delay: 0,
      // smooth: true,
      spy: true,
      smooth: 'easeInOutQuart',
      offset: offset
    })
  }
  
  render() {

    return (

      
            <div className="navigation">
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Toggle  aria-controls="responsive-navbar-nav" />
          
                  <Navbar.Collapse  id="responsive-navbar-nav">
                    <Nav  className="mr-auto">
              <div className='link-items'>

                <NavDropdown.Item href="#home" className="link-item"
                  onClick={this.scrollToTop} duration={700}>Home</NavDropdown.Item>

                <NavDropdown.Item href="#about-me" className="link-item" onClick={() => this.scrollTo('about-me',-80)}>
                About
                  
                </NavDropdown.Item>

            <NavDropdown.Item href="#portfolio" className="link-item" onClick={() => this.scrollTo('portfolio',-80)}>Portfolio
                
                </NavDropdown.Item>

              <NavDropdown.Item href="#contact" className="link-item"
                  onClick={this.scrollToBottom}
                  duration={700}>Contact</NavDropdown.Item>

              </div>

           
          </Nav>
        </Navbar.Collapse>
        </Navbar>
        
      </div>

    )
  }
}


