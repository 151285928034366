import React from 'react'

export default function About() {
  return (
    <div className="about-section">
      <p className="section-title" id="about-me">About Me</p>
      <div className="flex-row about-me">
        <img src='./me2.jpg' alt="profile pic" className="profile-pic"></img>
        <p className="quote">"Having collaborated with people from diverse backgrounds, I seek opportunities to reinvent my career and explore my passion for solving problems through technology."</p>
      </div>
    </div>
  )
}
