import React, { Component } from 'react'

export default class Skills extends Component {
  constructor() {
    super()
    this.state = {
      skills: ['HTML', 'CSS', 'JavaScript', 'Git', 'GitHub', 'React', 'Ruby', 'Rails', 'Heroku', 'Sequelize', 'PostgreSQL', 'XD', 'Zeplin']
    }
  }
  render() {
    return (
      <div className="skills">
        <p className="section-title">Skills and Frameworks</p>
        {this.state.skills.map(skill =>
          
      <img key={skill} className="skill-icon" src={require(`../skills/${skill}.png`)} alt="skill-logo" title={skill}/>
        )}
        </div>
      )
    }
}

